import appLocaleData from 'react-intl/locale-data/tr';
import trMessages from '../locales/tr_TR.json';

const TrLang = {
  messages: {
    ...trMessages
  },
  locale: 'tr-TR',
  data: appLocaleData
};
export default TrLang;
