import {
    HIDE_MESSAGE,
    INIT_URL,
    SHOW_MESSAGE,
    SIGNOUT_USER_SUCCESS,
    SIGNIN_USER
} from "constants/ActionTypes";
import config from "config";
const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    // For Login Operation
    authUser: localStorage.getItem('userId'),
    myuser: localStorage.getItem('myuser'),
    userId: localStorage.getItem('userId'),
    roles: localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : [],
    avatarurl: localStorage.getItem('avatarurl'),
    inst: localStorage.getItem('inst'),
    instCode: localStorage.getItem('instCode'),
    token: localStorage.getItem('token')
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNIN_USER: {
            return {
                ...state,
                authUser: action.payload.userId,
                myuser: action.payload.myuser,
                userId: action.payload.userId,
                roles: action.payload.roles,
                avatarurl: action.payload.avatarurl,
                inst: action.payload.inst,
                instCode: action.payload.instCode,
                token: action.payload.token,
                loader: false
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                myuser:null,
                userId:null,
                roles:null,
                avatarurl:null,
                inst: null,
                instCode: null,
                token:null,
                initURL: `${config.webBbsHost}bbs/mainPage`,
                loader: false
            }
        }
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
        default:
            return state;
    }
}
