let  webHost = "";
let  apiHost = "";
let  bbsHost = "";
let  webBbsHost = "";
let port = 0;

const devConfig = {
    webHost : "http://localhost:3300/",
    apiHost : "http://localhost:3300/",
    bbsHost : "https://testgiris.ayk.gov.tr/api/",
    webBbsHost :"https://testgiris.ayk.gov.tr/",
    port : 3300
};
const testConfig = {
    webHost :"https://testybs.ayk.gov.tr/",
    apiHost : "https://testybs.ayk.gov.tr/api/",
    bbsHost : "https://testgiris.ayk.gov.tr/api/",
    webBbsHost :"https://testgiris.ayk.gov.tr/",
    port : 3313
};
const prodConfig = {
    webHost : "https://ybs.ayk.gov.tr",
    apiHost : "https://ybs.ayk.gov.tr/api/",
    bbsHost : "https://giris.ayk.gov.tr/api/",
    webBbsHost :"https://giris.ayk.gov.tr/",
    port : 3310
};

if(process.env.REACT_APP_ENV === "development"){
    apiHost = devConfig.apiHost;
    webHost = devConfig.webHost;
    bbsHost = devConfig.bbsHost;
    webBbsHost = devConfig.webBbsHost;
    port = devConfig.port;
}
else if(process.env.REACT_APP_ENV === "test"){
    apiHost = testConfig.apiHost;
    webHost = testConfig.webHost;
    bbsHost = testConfig.bbsHost;
    webBbsHost = testConfig.webBbsHost;
    port = testConfig.port;

}
else if(process.env.REACT_APP_ENV === "production"){
    apiHost = prodConfig.apiHost;
    webHost = prodConfig.webHost;
    bbsHost = prodConfig.bbsHost;
    webBbsHost = prodConfig.webBbsHost;
    port = prodConfig.port;

}

export default {
    projectName : "Yönetim Bilgi Sistemi",
    projectUrl : `${webHost}`,
    version : "v1",
    jwtSecret : "bbs2019",
    passSecret : "sanal17",
    activateSecret : "sanal17",
    apiHost,
    bbsHost,
    webBbsHost,
    port : port,
    loginUrl : `${webHost}`,
}
