import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { admin_roles, board_roles, library_roles, dig_auth_inst } from '../../appConfig';

class SidenavContent extends Component {
  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open')
          }
        }
        this.classList.toggle('open');
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {

    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] == 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  ArrangeMenu = (inst) =>{
    if (dig_auth_inst.some(r=>r===inst)) {
      return (
        <li>
        <NavLink className="prepend-icon" to="/app/etkinlik3">
          <span className="nav-text"><IntlMessages id="sidebar.etkinlik.win3"/></span>
        </NavLink>
        </li>
      );
    }
  };
  render() {

    const inst = this.props.inst
    const roles = this.props.roles

    return (
      <CustomScrollbars className=" scrollbar">
        { 
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main"/>
          </li>
          <li className="nav-header2">
            <IntlMessages id="sidebar.secondary1"/>
          </li>
            {/* BOARD */}
            {board_roles.some(br=>roles.some(r=>br===r)) &&
            <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.board"/>
              </span>
            </Button>
            <ul className="sub-menu">
            <li>
                <NavLink className="prepend-icon" to="/app/ykkUygulanma">
                  <span className="nav-text"><IntlMessages id="sidebar.board.win0"/></span>
                </NavLink>
            </li>
            </ul>
          </li>
          }   
          {
          admin_roles.some(ar=>roles.some(r=>ar===r)) && 
           <>
          {/* BURS SİSTEMİ */}    
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.burs"/>
              </span>
            </Button>
            <ul className="sub-menu">
            <li>
                <NavLink className="prepend-icon" to="/app/bursmain">
                  <span className="nav-text"><IntlMessages id="sidebar.burs.win0"/></span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="prepend-icon" to="/app/bursiyer1">
                  <span className="nav-text"><IntlMessages id="sidebar.burs.win3"/></span>
                </NavLink>
              </li> */}
              <li>
                <NavLink className="prepend-icon" to="/app/burs1">
                  <span className="nav-text"><IntlMessages id="sidebar.burs.win1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/burs2">
                  <span className="nav-text"><IntlMessages id="sidebar.burs.win2"/></span>
                </NavLink>
              </li>
              
            </ul>
          </li>
          {/* ETKİNLİK SİSTEMİ */}
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.etkinlik"/>
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/etkinlikmain">
                  <span className="nav-text"><IntlMessages id="sidebar.etkinlik.win0"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/etkinlik1">
                  <span className="nav-text"><IntlMessages id="sidebar.etkinlik.win1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/etkinlik2">
                  <span className="nav-text"><IntlMessages id="sidebar.etkinlik.win2"/></span>
                </NavLink>
              </li>
              {this.ArrangeMenu(inst)} 
            </ul>
          </li>

           {/* PROJE SİSTEMİ */}
           <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.proje"/>
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/proje1">
                  <span className="nav-text"><IntlMessages id="sidebar.proje.win1"/></span>
                </NavLink>
              </li>
              
              
            </ul>
          </li>

          {/* KİTAP SATIŞ SİSTEMİ */}
           <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.kitapsatis"/>
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/ucretsizyayin">
                  <span className="nav-text"><IntlMessages id="sidebar.kitapsatis.win1"/></span>
                </NavLink>
              </li>
            </ul>
            {/* <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/fuar">
                  <span className="nav-text"><IntlMessages id="sidebar.kitapsatis.win2"/></span>
                </NavLink>
              </li>
            </ul> */}
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/expo">
                  <span className="nav-text">Fuarlar</span>
                </NavLink>
              </li>
            </ul>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/kitapgelir">
                  <span className="nav-text"><IntlMessages id="sidebar.kitapsatis.win3"/></span>
                </NavLink>
              </li>
            </ul>
          </li> 

           {/* YAYSİS */}
           <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.yayin"/>
              </span>
            </Button>
            <ul className="sub-menu">
            <li>
                <NavLink className="prepend-icon" to="/app/yayindevam">
                  <span className="nav-text"><IntlMessages id="sidebar.yayin.win0"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/yayin">
                  <span className="nav-text"><IntlMessages id="sidebar.yayin.win1"/></span>
                </NavLink>
              </li>
            </ul>
          </li> 
          </>   
          }
          {
          library_roles.some(lr=>roles.some(r=>lr===r)) &&
          <>
           {/* KÜTÜPHANE SİSTEMİ */}
           <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.libreport"/>
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/libmaterialreport">
                  <span className="nav-text"><IntlMessages id="sidebar.libreport.win1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/libreaderreport">
                  <span className="nav-text"><IntlMessages id="sidebar.libreport.win2"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/libstorereport">
                  <span className="nav-text"><IntlMessages id="sidebar.libreport.win3"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/arcstorereport">
                  <span className="nav-text"><IntlMessages id="sidebar.libreport.win4"/></span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="prepend-icon" to="/app/libraryMaterialReport">
                  <span className="nav-text">Kütüphane Materyali</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/libraryMaterialTable">
                  <span className="nav-text">Kütüphane Mevcudu 2</span>
                </NavLink>
              </li> */}
            </ul>
          </li> 

          <li className="nav-header2">
            <IntlMessages id="sidebar.secondary3"/>
          </li>

          {/* KÜTÜPHANE SİSTEMİ VERİ GİRİŞİ */}
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.library"/>
              </span>
            </Button>
            <ul className="sub-menu">
            <li>
                <NavLink className="prepend-icon" to="/app/librarymaterial">
                  <span className="nav-text"><IntlMessages id="sidebar.library.win0"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/libraryreaders">
                  <span className="nav-text"><IntlMessages id="sidebar.library.win1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/librarystore">
                  <span className="nav-text"><IntlMessages id="sidebar.library.win2"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/archivestore">
                  <span className="nav-text"><IntlMessages id="sidebar.library.win3"/></span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="prepend-icon" to="/app/libraryMaterialEntry">
                  <span className="nav-text">Kütüphane Veri Girisi</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/archiveMaterialEntry">
                  <span className="nav-text">Arşiv Veri Girisi</span>
                </NavLink>
              </li> */}
            </ul>
          </li>
          </>
          }
          </ul>
         }
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({auth}) => ({inst: auth.inst, roles: auth.roles})
export default connect(mapStateToProps)(withRouter(SidenavContent));