import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import darkTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';
import config from "config";

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from 'constants/ThemeColors';
import MainApp from 'app/index';
import {setInitUrl, userSignOutSuccess, userSingIn} from '../actions/Auth';
//import {setMyUser} from '../actions/Auth';//kapanacak
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import axios from 'axios';
import { localLogin, localExit, getReduxLoginUser } from '../appConfig';

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/',
            state: {from: props.location}
          }}
        />}
  />;

class App extends Component {

  state = {
    loading: 'true',
  }

  getDataPromise = async (param) => {
    return axios({
        // url: 'http://localhost:3300/v1/user/'+param,
          url: `${config.apiHost}${config.version}/user/`+param,
          method: 'get',
          timeout: 8000,
          headers: {
              'Content-Type': 'application/json',
          }
      })
    .then(res => res.data)
    .catch (err => console.error(err))
  }

  async componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if(process.env.REACT_APP_ENV === "development"){

      // set params for dev
      const userId  = 12671

      axios.interceptors.request.use(function (conf) {
        conf.params = {...conf.params, userId}
        return conf;
      })
     
      if(localStorage.getItem("myuser") === null || localStorage.getItem("myuser") === undefined || localStorage.getItem("myuser") === "null"){
        this.setState({ loading: 'true' });

        await this.getDataPromise(userId).then( res => {
          // Local login operation
          localLogin({...res, userRoles: [{id: 62}]}, null)

          // Redux login operation
          this.props.dispatch(userSingIn(getReduxLoginUser({...res, userRoles: [{id: 62}]}, null)))

          this.setState({ loading: 'false' });
        });
      }
      else this.setState({ loading: 'false' });
    }
  
  else if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "test"){

    const paramsString = this.props.location.search;
    const token = this.urlParams(paramsString,'t') !== null ? this.urlParams(paramsString,'t').split("?")[0] : localStorage.getItem("token");

    axios.interceptors.request.use(function (conf) {
      conf.headers.Authorization = token
      conf.params = {...conf.params, userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null}
      return conf;
    })
    
   if(localStorage.getItem("myuser") === null || localStorage.getItem("myuser") === undefined || localStorage.getItem("myuser") === "null"){
    this.setState({ loading: 'true' });
    await axios
      .post(
        `${config.bbsHost}${config.version}/user/check/otherApp`,
        {token}
      )
      .then(response => {
       if(response.data.status === true){   
       
          const user = response.data.user;

          // Local login operation
          localLogin(user, token)

          // Redux login operation
          this.props.dispatch(userSingIn(getReduxLoginUser(user, token)))

          axios.interceptors.request.use(function (conf) {
            conf.params = {...conf.params, userId: user.id}
            return conf;
          })
   
          this.setState({ loading: 'false' });
        }
        else{
          //Local Exit
          localExit()
          //Redux Exit
          this.props.dispatch(userSignOutSuccess())

          this.setState({ loading: 'false' });
          window.location.replace(`${config.webBbsHost}bbs/mainPage`);
        }

      }).catch(e => { 
        window.location.replace(`${config.webBbsHost}bbs/mainPage`);
      });
    }
    else this.setState({ loading: 'false' });
  }
    
  }

  urlParams = (url,name)=>{
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
  }


  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default : createMuiTheme(indigoTheme);
    }
    return applyTheme;
  }

  render() {
    if (this.state.loading === 'true') {
      return <h2>Yükleniyor...</h2>;
    }

    const {match, location, themeColor, isDarkTheme, locale, initURL, isDirectionRTL} = this.props;
    let applyTheme = createMuiTheme(indigoTheme);
    if (isDarkTheme) {
      document.body.classList.add('dark-theme');
      applyTheme = createMuiTheme(darkTheme)
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }

    console.log('location.pathname :::: ' + initURL)
    if (location.pathname === '/') {
      
      if (localStorage.getItem('userId') === null || localStorage.getItem('userId') === "null") {
            window.location.replace(`${config.webBbsHost}bbs/mainPage`);
      }
      else if (initURL === "" || initURL === "/") {
         return ( <Redirect to={'/app/libmaterialreport'}/> );
      } 
      else {
         return ( <Redirect to={initURL}/> );
      }
    }

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} authUser={localStorage.getItem('userId')}
                                   component={MainApp}/>
                  <Route
                    component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }

}

const mapStateToProps = ({settings, auth}) => {
  const {themeColor, sideNavColor, darkTheme, locale, isDirectionRTL} = settings;
  const {initURL} = auth;
  return {themeColor, sideNavColor, isDarkTheme: darkTheme, locale, isDirectionRTL, initURL}
};

export default connect(mapStateToProps, {setInitUrl})(App);
