import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import LibraryMain from './LibraryMain'
import LibraryReportMain from './LibraryReportMain'
import Expo from './Expo';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  libraryMain: LibraryMain,
  libraryReportMain: LibraryReportMain,
  expo: Expo,
});
